/* eslint no-console:0 */
// admin_product_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "period",
        "forUsers"
    ];

    connect() {
        console.log("Work Note Connection");
        this.setVisibleOption();
    }

    setVisibleOption() {
        var periodType = this.periodTarget.options[
            this.periodTarget.selectedIndex
        ].value;
        console.log("Period Type: "+periodType);

        if(periodType == "single") {
            $("#work_note_single").show();
            $("#work_note_recurring_weekly").hide();
        } else if(periodType == "week") {
            $("#work_note_single").hide();
            $("#work_note_recurring_weekly").show();
        }
    }

    setVisibleUsers(e ) {
        console.log("Setting visible users");

        let clickedCheckbox = e.target;

        if(clickedCheckbox.value === 'all_staff') {
            this.forUsersTargets.forEach(checkbox => {
                if(checkbox.value !== 'all_staff') {
                    checkbox.checked = true;
                }
            });
        } else {
            let allStaffCheckBox = this.forUsersTargets.find(checkbox => 
                checkbox.value === 'all_staff'
            );

            if(clickedCheckbox.checked == false) {
                allStaffCheckBox.checked = false;
            }
        }

    }
}